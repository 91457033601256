<template>
    <div>
        <div class="qkxx_title">
            待结算稿件
        </div>
        <div class="qkcont">
            <div>
                <el-table

                        :data="tableData"
                        stripe
                        style="width: 100%"
                        :default-sort="{ prop: 'date', order: 'descending' }"
                >
                    <el-table-column prop="order_No" label="订单号" sortable>
                    </el-table-column>
                    <el-table-column prop="name" label="文章标题" sortable>
                    </el-table-column>
                    <el-table-column prop="operation_time" label="投稿时间" sortable>
                    </el-table-column>
                    <el-table-column prop="courierNum" label="快递单号" sortable>
                    </el-table-column>
                    <el-table-column fixed="right" label="操作" align="center">
                        <template slot-scope="scope">
                            <span style="cursor: pointer;" @click="downloadAttach(scope.row)"><i
                                    class="el-icon-download"></i>查看凭证</span>&nbsp;&nbsp;
                            <span style="cursor: pointer;" @click="courierInfo(scope.row)"><i
                                    class="el-icon-upload"></i>快递单号</span>
                            <span style="color: red; margin-left: 5px; cursor: pointer;"
                                  @click="weitoyilist(scope.row)">确认完成</span>
                        </template>
                    </el-table-column>
                </el-table>
            </div>
            <div class="ygdPage">
                <el-pagination class="fy" small @size-change="handleSizeChange" :page-size="pagesize"
                               :page-sizes="[10,20,30]" layout="total, sizes, prev, pager, next, jumper"
                               @current-change="current_change" :total="total" background>
                </el-pagination>
            </div>
        </div>
        <el-dialog
                title="快递单号更新"
                :visible.sync="dialog"
                width="503px"
                :close-on-click-modal="false"
                center
        >
            <template slot-scope="scope">
                <el-input
                        v-model="form.competentunit"
                        placeholder="请输入快递单号"
                ></el-input>
                <br/><br/>

                <el-button class="left" type="danger" @click="confirm1(scope.row)" :loading="loading">确 定
                </el-button>
            </template>

        </el-dialog>
    </div>
</template>

<script>
    import order from '../components/order'
    import {orderdaijiesuan, weitoyi} from '@/API/QKD/balance'
    import {downloadFile} from '@/API/orderstatus'
    import {courierInfo, pingzheng,courierInfoIn} from "../../../API/QKD/balance";

    export default {
        components: {
            order
        },
        data() {
            return {
                son: 1,
                path: '/Periodical/unbalanced',
                childpath: '/Periodical/balanced/detail',
                total: 0, //默认数据总数
                pagesize: 10, //每页的数据条数
                currentPage: 1, //默认开始页面
                tableData: [],//订单列表数据
                orderlistlength: 2, //订单列表长度
                dialog: false, //默认不显示弹窗
                id: "",
                form: {
                    id: "",
                    competentunit: ""
                }
            }

        },
        methods: {
            // 下载凭证
            downloadAttach(row) {
                let Path = row.img_voucher
                row.isLook = true
                downloadFile(Path)


            },
            //弹窗
            courierInfo(row) {
                //显示弹框
                this.dialog = true;
                this.id = row.worksid;
            },

            confirm1() {
                courierInfo(this.id, this.form.competentunit).then(res => {
                    //  console.log(res)
                    if (res.data.code == 0) {
                        this.$message.success(res.data.msg)
                        this.dialog = false;
                        this.orderdaijiesuanlist();
                    } else {
                        this.$message.error(res.data.msg)
                        this.dialog = false;
                    }
                })
            },
            // 确定结算
            weitoyilist(row) {
                let _this = this
                let id = localStorage.getItem('QKId')
                courierInfoIn(this.currentPage, this.pagesize, row.worksid).then(res => {
                    for (var i = 0; i < res.data.data.length; i++) {
                        if (res.data.data[i].courierNum == null || res.data.data[i].courierNum == "") {
                            this.$message.error("请先上传快递单号")
                        } else {
                            if (row.isLook && row.img_voucher) {
                                weitoyi(row.worksid).then(res => {
                                    if (res.data.code == 0) {
                                        this.$message.success({
                                            message: res.data.data,
                                            duration: 1000,
                                            onClose() {
                                                _this.$router.push('/Periodical/balanced/?id=9')
                                            }
                                        });
                                        this.orderdaijiesuanlist()
                                    } else {
                                        this.$message({
                                            message: res.data.msg,
                                            type: 'warning'
                                        });
                                    }
                                })
                            } else {
                                this.$message({
                                    message: '请先查看凭证',
                                    type: 'warning',
                                    duration: 1500
                                });
                            }
                        }
                    }


                })


            },
            // 分页
            current_change: function (currentPage) {
                this.currentPage = currentPage;
                //				this.todoOrder();
            },
            handleSizeChange(val) {
                console.log(val);
                this.pagesize = val;
                //				this.todoOrder();
            },
            orderdaijiesuanlist() {
                let id = localStorage.getItem('QKId')
                pingzheng(this.currentPage, this.pagesize, id).then(res => {
                    console.log(res.data.data.list)
                    this.total = res.data.total
                    this.tableData = res.data.data.map(val => {
                        return {...val, isLook: false}
                    })

                })
            }
        },
        mounted() {
            this.orderdaijiesuanlist()
        }

    }
</script>

<style>
    .qkcont {
        padding: 10px;
    }

    .nodata {
        text-align: center;
        line-height: 80px;
        color: #e6e6e6;
    }

    .btn {
        display: flex;
        justify-content: flex-end;
        padding: 20px;
    }

    .ordertable {
        position: relative;
        display: flex;
        justify-content: space-between;
    }

    .biaoti {
        font-size: 14px;
    }

    .orderlist span {
        font-size: 12px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        margin: 0 3px;
    }

    .urgent {
        position: absolute;
        right: 0;
        top: 0;
        width: 40px;
        height: 24px;
        background: #FF1918;
        border-radius: 0px 4px 0px 4px;
        color: #fff;
        text-align: center;
        font-size: 12px;
        line-height: 24px;
    }

    .ygdPage {
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .left {
        margin: 0px auto;

    }
</style>